@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

:root {
  --primary-color: #e91e63;
  --secondary-color: #c2185b;
  --background-color: #fce4ec;
  --text-color: #333;
  --accent-color: #ff4081;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: linear-gradient(135deg, var(--background-color) 0%, #f8bbd0 100%);
  color: var(--text-color);
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

input,
button {
  font-size: 16px;
}

a,
button {
  min-height: 44px;
  min-width: 44px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  text-align: center;
  padding: 60px 40px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
}

.App-header::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.1;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

main {
  flex-grow: 1;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

section {
  margin: 4rem 0;
  text-align: center;
  padding: 2rem 1rem;
}

h1,
h2,
h3 {
  color: var(--primary-color);
}

h1 {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

h2 {
  font-size: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
}

#hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin: 4rem 0;
}

.hero-content {
  flex: 1;
  text-align: left;
}

.hero-image {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image img {
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.feature {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(5px);
  animation: float 4s ease-in-out infinite;
}

.hover-lift:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.feature-video {
  width: 100%;
  height: auto;
  background-color: #000;
  border: none;
  outline: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.who-we-are-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.who-we-are-text {
  flex: 1;
}

.who-we-are-image {
  flex: 1;
}

.who-we-are-image img {
  max-width: 350px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.founders-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 2rem;
}

.founder {
  text-align: center;
  transition: transform 0.3s ease;
}

.founder:hover {
  transform: translateY(-10px);
}

.founder-image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid var(--primary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.founder-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.founder-image-container.alessia .founder-image {
  transform: scale(1.8);
  object-position: center 15%;
}

.founder h3 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

.founder p {
  margin: 0;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  main {
    padding: 20px;
  }

  section {
    margin: 2rem 0;
  }

  .founders-container {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  #hero {
    flex-direction: column;
  }

  .hero-content,
  .hero-image {
    width: 100%;
    text-align: center;
  }

  .hero-image img {
    max-width: 250px;
  }

  .App-header h1 {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-content h2 {
    font-size: 1.8rem;
  }

  .hero-image img {
    max-width: 100%;
    height: auto;
  }

  .video-container {
    width: 100%;
  }

  .feature-video {
    width: 100%;
    height: auto;
  }

  .who-we-are-content {
    flex-direction: column;
  }

  .who-we-are-text,
  .who-we-are-image {
    width: 100%;
  }

  .feature-grid {
    grid-template-columns: 1fr;
  }

  .founders-container {
    flex-direction: column;
  }

  .founder {
    width: 100%;
    margin-bottom: 2rem;
  }

  .animated-form input,
  .animated-form button {
    width: 100%;
    margin-bottom: 1rem;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 2rem auto;
}

input,
button.cta-button {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  box-sizing: border-box;
}

input {
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.hover-input:hover,
.hover-input:focus {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

button.cta-button {
  background-color: var(--accent-color);
  color: white;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

button.cta-button:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Typing effect for header subtitle */
.App-header .hero-subtitle {
  overflow: hidden;
  border-right: 0.15em solid var(--primary-color);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--primary-color);
  }
}

/* Remove typing effect from hero section */
#hero .hero-subtitle {
  animation: none;
  border-right: none;
  white-space: normal;
  letter-spacing: normal;
}

/* Fade-in animation for sections */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Slide-in animation for sections */
.slide-in-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.slide-in-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.slide-in-left.visible,
.slide-in-right.visible {
  opacity: 1;
  transform: translateX(0);
}

.animated-text {
  display: inline-block;
  transition: transform 0.3s ease;
}

.animated-text:hover {
  transform: scale(1.05);
}

footer {
  margin-top: auto;
  text-align: center;
  padding: 40px 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

footer p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--text-color);
  opacity: 0.8;
}

.App-footer {
  background-color: #282c34;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding: 20px;
  margin-top: 20px;
}

.App-footer h2 {
  margin-bottom: 10px;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
}
